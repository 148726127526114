'use client';

import { useEffect, useState } from 'react';

import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import createLegacyRouter from 'legacyRouter';
import { usePathname, useSearchParams } from 'next/navigation';
import createStore from 'store';

import { fetchAuthIfNeeded } from 'dux/auth/actions';
import { setUserCountry, setUserRegion } from 'dux/user/actions';
import { locationChanged } from 'dux/router/slice';

const store = createStore();

const persistor = persistStore(store);

const nextJsPages = ['/reviews'];

type LayoutProps = {
  children: React.ReactNode;
  userGeolocation: {
    country: string;
    region: string;
  };
};

const StoreProvider = ({ children, userGeolocation }: LayoutProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const url = searchParams.toString() ? `${pathname}?${searchParams}` : `${pathname}`;
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);

  useEffect(() => {
    store.dispatch(
      locationChanged({
        location: {
          pathname,
          search: searchParams.toString(),
        },
      }),
    );
    createLegacyRouter();
    /**
     * @Marc: ensure to be fetching on the first state that gets rendered
     * This will trigger feature flag fetch via middleware
     */
    store.dispatch(fetchAuthIfNeeded());
  }, []);

  useEffect(() => {
    // Sync Redux Store
    store.dispatch(
      locationChanged({
        location: {
          pathname,
          search: searchParams.toString(),
        },
      }),
    );
    // Sync React Router
    const reactRouter = createLegacyRouter();
    if (previousUrl && nextJsPages.includes(previousUrl) && !nextJsPages.includes(pathname)) {
      // The code below must be run only when going from Next.js to React
      reactRouter.navigate(url);
    }
    setPreviousUrl(pathname);
  }, [pathname, searchParams]);

  useEffect(() => {
    // Storing the user guessed country to make it accessible to all pages
    store.dispatch(setUserCountry(userGeolocation.country));
    store.dispatch(setUserRegion(userGeolocation.region));
  }, [userGeolocation]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default StoreProvider;
