'use client';

import React, { useEffect } from 'react';

/**
 * Override console.error
 * Temporary hack to turn defaultProps errors into warnings until TypeScript migration is complete
 * Issue: https://github.com/recharts/recharts/issues/3615
 */
export const TurnOffDefaultPropsWarning: React.FC = () => {
  useEffect(() => {
    return () => {
      console.error = new Proxy(console.error, {
        apply: (target, _thisArg, argArray) => {
          if (
            typeof argArray[0] === 'string' &&
            (/defaultProps/.test(argArray[0]) ||
              /UNSAFE_componentWillMount/.test(argArray[0]) ||
              (typeof argArray[1] === 'string' && /fetchPriority/.test(argArray[1])))
          ) {
            return console.warn(...argArray);
          }
          return target(...argArray);
        },
      });
    };
  });

  return null;
};
