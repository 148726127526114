'use client';

import { useEffect, useState } from 'react';

import { styled } from '@prose-ui/legacy';

const Root = styled.div`
  position: fixed;
  top: 105px;
  right: 0;
  opacity: 0.9;
  background: rgb(255 0 255 / 90%);
  color: white;
  z-index: 99999;

  font-size: 11px;
  line-height: 1.2;
  padding: 0.5em;
`;

type NodeStylingData = {
  'font-family': string;
  'font-weight': string;
  'font-size (px)': number;
  'letter-spacing': string;
  'line-height (%)': number;
  'line-height (px)'?: string;
};

// For now we add these variables to window for having a quick and dirty way
// to update those values for the user (e.g. StyleAudit.referenceStyles = {...} in the console)
declare global {
  interface Window {
    StyleAudit?: {
      referenceStyles: Record<string, NodeStylingData>;
      options: { withInfoOverlay: boolean };
    };
  }
}
const setupWindowVariables = () => {
  window.StyleAudit = {
    options: { withInfoOverlay: true },
    referenceStyles: {
      /* values coming from Figma: https://www.figma.com/design/pbOZJ5C6STlTYHKlJD7aNk/Design-System-Foundations?node-id=10446-4266&node-type=section&t=UQX471snUsJt9hWi-0 */
      Heading1: {
        'font-family': 'Saol',
        'font-weight': '300',
        'font-size (px)': 48,
        'letter-spacing': '-0.5px',
        'line-height (%)': 110,
      },
      Heading2: {
        'font-family': 'Saol',
        'font-weight': '300',
        'font-size (px)': 28,
        'letter-spacing': '-0.5px',
        'line-height (%)': 110,
      },
      Heading3: {
        'font-family': 'Saol',
        'font-size (px)': 32,
        'font-weight': '300',
        'line-height (%)': 110,
        'letter-spacing': '-0.32px',
      },
      Heading4: {
        'font-family': 'Saol',
        'font-size (px)': 24,
        'font-weight': '300',
        'line-height (%)': 110,
        'letter-spacing': '-0.24px',
      },
      Heading5: {
        'font-family': 'Saol',
        'font-size (px)': 20,
        'font-weight': '300',
        'line-height (%)': 110,
        'letter-spacing': '-0.2px',
      },
      Heading6: {
        'font-family': 'Saol',
        'font-size (px)': 16,
        'font-weight': '300',
        'line-height (%)': 110,
        'letter-spacing': '-0.16px',
      },
      Body1Regular: {
        'font-family': 'Simplon Norm',
        'font-size (px)': 16,
        'font-weight': '400',
        'line-height (%)': 150,
        'letter-spacing': '0.16px',
      },
      Body1Bold: {
        'font-family': 'Simplon Norm',
        'font-size (px)': 16,
        'font-weight': '500',
        'line-height (%)': 150,
        'letter-spacing': '0.16px',
      },
      Body2Regular: {
        'font-family': 'Simplon Norm',
        'font-size (px)': 14,
        'font-weight': '400',
        'line-height (%)': 150,
        'letter-spacing': '0.14px',
      },
      Body2Bold: {
        'font-family': 'Simplon Norm',
        'font-size (px)': 14,
        'font-weight': '500',
        'line-height (%)': 150,
        'letter-spacing': '0.14px',
      },
      Body3Regular: {
        'font-family': 'Simplon Norm',
        'font-size (px)': 12,
        'font-weight': '400',
        'line-height (%)': 150,
        'letter-spacing': '0.12px',
      },
      Body3Bold: {
        'font-family': 'Simplon Norm',
        'font-size (px)': 12,
        'font-weight': '500',
        'line-height (%)': 150,
        'letter-spacing': '0.12px',
      },
      Label1Medium: {
        'font-family': 'Simplon Mono',
        'font-size (px)': 14,
        'font-weight': '500',
        'line-height (%)': 100,
        'letter-spacing': '1.12px',
      },
      Label2Medium: {
        'font-family': 'Simplon Mono',
        'font-size (px)': 12,
        'font-weight': '500',
        'line-height (%)': 100,
        'letter-spacing': '0.96px',
      },
      Label1Regular: {
        'font-family': 'Simplon Mono',
        'font-size (px)': 14,
        'font-weight': '400',
        'line-height (%)': 100,
        'letter-spacing': '1.12px',
      },
      Label2Regular: {
        'font-family': 'Simplon Mono',
        'font-size (px)': 12,
        'font-weight': '400',
        'line-height (%)': 100,
        'letter-spacing': '0.96px',
      },
      Label3Regular: {
        'font-family': 'Simplon Mono',
        'font-size (px)': 10,
        'font-weight': '400',
        'line-height (%)': 100,
        'letter-spacing': '0.8px',
      },
    },
  };
};

type NodeData = NodeStylingData & {
  instance: HTMLElement;
  'matchedVariant(s)': string;
};

const matchAgainstReference = (
  referenceStyles: Record<string, NodeStylingData>,
  nodeData: NodeStylingData,
) => {
  const minimumMatches = Object.entries(referenceStyles).filter(
    ([_variantKey, variantValues]) =>
      nodeData['font-family'] === variantValues['font-family'] &&
      nodeData['font-size (px)'] === variantValues['font-size (px)'],
    // && nodeData['font-weight'] === variantValues['font-weight'] // commented-out because not super helpful while we currently have questionable font face definitions (e.g. Simplon Mono)
  );

  const exactMatch = minimumMatches.find(
    ([_variantKey, variantValues]) =>
      nodeData['line-height (%)'] === variantValues['line-height (%)'],
    // && nodeData['letter-spacing'] === variantValues['letter-spacing']
  );

  if (exactMatch) return ['exact_match', `🟢 ${exactMatch[0]}`] as const;

  if (minimumMatches.length > 0) {
    return ['inexact_match', `🟡 ${minimumMatches.map((v) => v[0]).join(', ')}`] as const;
  }

  return ['no_match', '❌'] as const;
};

function executeAuditScript() {
  if (!window?.StyleAudit) return;

  const textNodes: NodeData[] = [];

  const elementsWithText = [
    ...document.querySelectorAll(
      ':is(*:not(:has(> *)):not(:empty), :has(> :is(br, span, strong, b, a, sup, button, .__style-audit-info__))):not(.__style-audit-info__)',
    ),
  ].filter(
    (el) =>
      [...el.childNodes].some((chn) => chn.nodeType === 3) &&
      getComputedStyle(el).display !== 'none',
  );

  elementsWithText.forEach((elementNode) => {
    if (!(elementNode instanceof HTMLElement)) {
      console.warn(
        'elementsWithText contains an element which is not expected, please fix the selection/filtering logic: ',
        elementNode,
      );
      return;
    }

    const computedStyle = getComputedStyle(elementNode);

    const nodeStylingData: NodeStylingData = {
      'font-family': computedStyle.fontFamily.split(',')[0]!.replaceAll('"', ''),
      'font-weight': computedStyle.fontWeight,
      'font-size (px)': Number(computedStyle.fontSize.slice(0, -2)),
      'letter-spacing': computedStyle.letterSpacing,
      'line-height (%)': Math.round(
        (Number(computedStyle.lineHeight.slice(0, -2)) /
          (Number(computedStyle.fontSize.slice(0, -2)) || 0.0000001)) *
          100,
      ),
      'line-height (px)': computedStyle.lineHeight.slice(0, -2),
    };

    const match = matchAgainstReference(window.StyleAudit!.referenceStyles, nodeStylingData);
    let matchingColor = '255 0 255'; // unexpected color
    switch (match[0]) {
      case 'exact_match':
        matchingColor = '0 255 0';
        break;
      case 'inexact_match':
        matchingColor = '240 170 0';
        break;
      case 'no_match':
        matchingColor = '255 0 0';
        break;
      default:
        break;
    }

    elementNode.style.setProperty(
      'text-shadow',
      `0 0 0.2ch rgba(${matchingColor}/ 100%), 0 0 0.2ch rgba(${matchingColor}/ 100%)`,
    );
    elementNode.style.setProperty('box-shadow', `inset 0 0 0 100vmax rgba(${matchingColor}/ 18%)`);

    /* remove all .__style-audit-info__ elements if they exist */
    [...document.querySelectorAll('.__style-audit-info__')].forEach((el) => el.remove());

    setTimeout(() => {
      if (window.StyleAudit?.options.withInfoOverlay) {
        /* add all .__style-audit-info__ elements */
        const infoOverlayElement = document.createElement('div');
        infoOverlayElement.setAttribute('class', '__style-audit-info__');
        infoOverlayElement.setAttribute(
          'style',
          `padding: 2px; position: absolute; transform: translateY(-33%); white-space: nowrap; color: white; text-shadow: 0 0 3px black; font-size: 9px; font-family: sans-serif; line-height: 1; background: rgb(${matchingColor} / 33%); text-transform: none;`,
        );
        infoOverlayElement.appendChild(document.createTextNode(match[1]));
        elementNode.appendChild(infoOverlayElement);
      }
    }, 150);

    textNodes.push({
      instance: elementNode,
      ...nodeStylingData,
      'matchedVariant(s)': match[1],
    });
  });

  let generatedStyleIndex = 0;
  const uniqueStyleIndexes: Map<string, number> = new Map([]);

  type LoggedTableRow = NodeStylingData & {
    count: number;
    instances: Element[];
  };

  const textNodesOverviewData = textNodes.reduce(
    (acc, nodeData) => {
      const { instance, ...nodeStyleData } = nodeData;
      const strData = JSON.stringify(nodeStyleData);

      let uniqueStyleIndex = uniqueStyleIndexes.get(strData);

      if (!uniqueStyleIndex) {
        generatedStyleIndex += 1;
        uniqueStyleIndex = generatedStyleIndex;
        uniqueStyleIndexes.set(strData, generatedStyleIndex);
      }

      return {
        ...acc,
        [uniqueStyleIndex]: {
          ...nodeStyleData,
          count: (acc[uniqueStyleIndex]?.count || 0) + 1,
          instances: acc[uniqueStyleIndex]?.instances
            ? [...acc[uniqueStyleIndex]!.instances, instance]
            : [instance],
        },
      };
    },
    {} as Record<number, LoggedTableRow>,
  );

  console.log(
    `----------///---------- Style audit on page ${window.location.href} ----------///---------- `,
  );
  console.log('Elements containing text:', textNodes.length);
  console.log('Unique text stylings:', Object.values(textNodesOverviewData).length);
  console.table(textNodesOverviewData);
  console.log(
    '----------------------------------------------------------------------------------------- ',
  );
}

export const StyleAuditTool = () => {
  const [isGUIActive, setIsGUIActive] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setupWindowVariables();
    }

    const callback = (event: KeyboardEvent) => {
      if (/* press option+A or alt+A */ event.code === 'KeyA' && event.altKey) {
        executeAuditScript();
        setIsGUIActive(true);
      }
    };
    document.body.addEventListener('keydown', callback);
    console.log(
      "The style-audit-tool is active. Press 'option+A' (or 'alt+A') while focusing the website to activate it.",
    );

    return () => {
      document.body.removeEventListener('keydown', callback);
    };
  }, []);

  return isGUIActive && <Root>⚙️ YOU ARE DEBUGGING STYLES</Root>;
};
